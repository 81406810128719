<template>
  <div>
    <b-form @submit.prevent="handleSubmitCredentials()">
      <b-form-group :label="$t('general.name')">
        <b-form-input
          v-model="$v.name.$model"
          :class="$v.name.$error && 'is-invalid'"
          trim
        >
          <b-form-invalid-feedback v-if="!$v.name.required">
            {{ $t("auth.type-valid-name") }}
          </b-form-invalid-feedback>
        </b-form-input>
      </b-form-group>
      <b-form-group :label="$t('general.email')">
        <b-form-input
          placeholder="email@email.com"
          v-model="$v.email.$model"
          :class="$v.email.$error && 'is-invalid'"
          trim
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.email.email || !$v.email.required">
          {{ $t("auth.type-valid-email") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Cargo">
        <multiselect
          :close-on-select="true"
          :show-labels="false"
          :class="$v.position.$error && 'is-invalid'"
          v-model="$v.position.$model"
          :options="positions"
          placeholder="Selecione o cargo"
          label="name"
        >
        </multiselect>
        <b-form-invalid-feedback v-if="!$v.position.required">
          Selecione o cargo
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button variant="link" @click="$emit('submited')">Cancelar</b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="isLoading"
          class="pull-right text-right"
        >
          <b-spinner v-if="isLoading" small class="mr-2" />
          <span> {{ $t("general.save") }}</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>
 
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  props: {
    id: {
      type: [String, Number],
    },
    isAdd: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["list_jobs_positions"]),
  },
  data() {
    return {
      isLoading: false,
      name: null,
      email: null,
      positions: [],
      position: null,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(4),
    },
    email: {
      required,
      email,
    },
    position: {
      required,
    },
  },

  methods: {
    ...mapActions([
      "add_employee",
      "get_employee",
      "show_toast",
      "edit_employee",
      "get_all_jobs_position",
    ]),
    handleSubmitCredentials() {
      this.isLoading = true;
      if (!this.$v.$anyError && !this.$v.$anyError) {
        this.$v.$touch();
        if (this.isAdd) {
          this.saveEmployees();
        } else {
          this.editEmployees();
        }
      }
    },
    saveEmployees() {
      this.add_employee({
        name: this.name,
        email: this.email,
        job_position_id: this.position.id,
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Cadastro realizado com sucesso",
              type: "success",
            });
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },
    editEmployees() {
      this.edit_employee({
        name: this.name,
        email: this.email,
        job_position_id: this.position.id,
        id: this.id,
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Edição realizada com sucesso",
              type: "success",
            });
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },
    getEdit() {
      this.get_employee(this.id).then(
        ({ data: { name, email, job_position_id, job_position } }) => {
          this.name = name;
          this.email = email;
          this.position = {
            id: job_position_id,
            name: job_position,
          };
        }
      );
    },
  },

  mounted() {
    this.get_all_jobs_position().then(({ data }) => {
      this.positions = data.results;
    });

    if (!this.isAdd) {
      this.getEdit();
    }
  },
};
</script>

 