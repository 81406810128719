<template>
  <div>
    <div>
      <FileUpload />
    </div>
    <b-modal
      id="alert-excel"
      centered
      size="md"
      no-close-on-backdrop
      hide-header-close
      header-text-variant="danger"
      header-class="text-center"
      title="Importante!"
    >
      <div>
        <p>
          Para que consigamos importar os cadastros é necessário que a planilha
          esteja sem nenhuma formatação ou linhas em branco.
        </p>
        <div class="">
          <strong> O que irá prejudicar a importação dos dados: </strong>
          <div class="mt-2 mb-4">
            <p>
              <b-icon icon="x" variant="danger" class="mr-2" /> Linhas em branco
              e/ou ocultas
            </p>
            <p>
              <b-icon icon="x" variant="danger" class="mr-2" /> Colunas em
              branco e/ou ocultas
            </p>
            <p>
              <b-icon icon="x" variant="danger" class="mr-2" /> Formatação com
              estilos de linha e/ou colunas
            </p>
          </div>
        </div>
        <div>
          <strong>
            Sua planilha deve estar preenchida da seguinte forma:
          </strong>
          <div class="mt-3">
            <table class="table table-bordered table-sm">
              <tr>
                <th>nome</th>
                <th>email</th>
                <th>posicao</th>
              </tr>
              <tr>
                <td>Reginaldo da Silva</td>
                <td>reginaldo.silva@emailempresa.com</td>
                <td>Auxiliar financeiro</td>
              </tr>
              <tr>
                <td>Camila Campelo da Cunha</td>
                <td>camila.cunha@emailempresa.com</td>
                <td>Gerente logistica</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <template #modal-footer="{ close }">
        <b-button variant="primary" @click="close()"
          >Eu entendi sobre o formato do arquivo</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import FileUpload from "@/components/FileUpload";

export default {
  components: {
    FileUpload,
  },
  methods: {},
  mounted() {
    setTimeout(() => this.$bvModal.show("alert-excel"), 500);
  },
};
</script>
