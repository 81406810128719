<template>
  <div>
    <div>
      <small class="d-block mb-3 d-flex align-items-center">
        <b-icon class="h4 mr-2" icon="exclamation-circle"></b-icon>
        Adicionar o nome e e-mail de contato de 1 (um) gestor estratégico alocado em cada um dos 05 principais clientes da
        empresa. Será enviado uma pesquisa de satisfação para cada um deles.
      </small>
      <div class="d-flex justify-content-between mb-4">
        <div class="input-search col-md-5 p-0">
          <b-form-input autocomplete="off" @input="handleSearch" v-model="search" placeholder="Digite para pesquisar"
            class="col-3 mb-2" />
          <b-button variant="light" class="px-2 d-flex align-items-center absolute icon-action">
            <b-icon icon="search"></b-icon>
          </b-button>
        </div>
        <div class="d-flex align-items-center">
          <b-button variant="secondary" @click="handleAddNewUser()" class="btn-add mr-3 mb-0">
            Adicionar
          </b-button>
          <!-- <b-button
            variant="success"
            @click="handleImportExcel()"
            class="btn-add mb-0"
          >
            <b-icon icon="file-earmark-spreadsheet-fill" />
            Importar do excel
          </b-button> -->
        </div>
      </div>

      <cards-list grid="4">
        <b-card v-for="(item, index) in items" :key="index" class="mb-2 mr-2">
          <section class="d-md-block">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <avatar :name="item.name" />
              <div class="d-flex align-items-center">
                <b-icon @click="handleConfirmDelete(item)" v-b-popover.hover.top="'Excluir'" class="h6 mx-1 pointer mb-0"
                  icon="trash" />
                <b-icon class="h6 mx-1 pointer mb-0" icon="pencil-square" @click="handleEditUser(item.id)" />

                <b-modal :id="`delete-row_${item.id}`" centered size="sm">
                  <template #modal-header>
                    <h5>Cuidado!</h5>
                  </template>
                  <p>
                    Você apagará o registro selecionado. Essa ação não poderá
                    ser desfeita, deseja continuar?
                  </p>
                  <template #modal-footer="{ close }">
                    <b-button variant="link" @click="handleDeleteRow()">
                      Sim
                    </b-button>
                    <b-button variant="primary" @click="close()">Não</b-button>
                  </template>
                </b-modal>
              </div>
            </div>
            <section>
              <strong class="d-block w-100">
                {{ item.name }}
              </strong>
              <small class="mb-3 d-block">
                <b-icon icon="envelope" /> {{ item.email }}
              </small>
              <small class="d-block">
                <strong>Função:</strong> {{ item.job_position }}
              </small>
            </section>
          </section>
        </b-card>
      </cards-list>
      <empty-list text="Não há gestores estratégicos para serem exibidos" v-if="total === 0" />
    </div>
    <b-modal id="import-excel" centered hide-footer size="lg" no-close-on-backdrop
      title="Importar lista de gestores estratégicos do Excel">
      <Import />
    </b-modal>
    <b-modal id="add-employee" centered hide-footer size="md" no-close-on-backdrop
      :title="`${isAdd ? 'Adicionar' : 'Editar'} gestor estratégico`">
      <Form :id="selectedId" :isAdd="isAdd" @submited="handleCloseModal" />
    </b-modal>
    <b-pagination v-if="items && items.lenght > 1" @change="handleChangePage" v-model="current_page" :total-rows="total"
      :per-page="per_page" aria-controls="my-table" />
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import Form from "./form";
import Import from "./import";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Form,
    Import,
  },

  mixins: [listPaginated],

  data() {
    return {
      action_name: "employee",
      menus: [],
      params: {},
      selectedId: null,
      isAdd: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    ...mapActions(["get_all_employee"]),
    handleAddNewUser() {
      this.isAdd = true;
      this.$bvModal.show("add-employee");
    },
    handleImportExcel() {
      this.$bvModal.show("import-excel");
    },
    handleEditUser(id) {
      this.isAdd = false;
      this.selectedId = id;
      this.$bvModal.show("add-employee");
    },
    handleCloseModal() {
      this.$bvModal.hide("add-employee");
      this.getList();
    },
  },
  created() {
    this.params.profile_id = this.user.profile_id;
    JSON.parse(JSON.stringify(this.items));
  },
};
</script>
